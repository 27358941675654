import { baseMsalConfig, baseTokenRequest, isLocal, msalConfigDev, msalConfigDevPartner } from 'ui-library';

export const msalConfig_dev: typeof baseMsalConfig = {
    ...msalConfigDev,
    auth: {
        ...msalConfigDev.auth,
        redirectUri: isLocal() ? 'http://localhost:3000/portal' : 'https://corporate.dev.imp.simcargo.eu/portal',
        postLogoutRedirectUri: window.location.origin,
    },
};

export const msalConfig_dev_partner: typeof baseMsalConfig = {
    ...msalConfigDevPartner,
    auth: {
        ...msalConfigDevPartner.auth,
        redirectUri: isLocal() ? 'http://localhost:3000/portal' : 'https://partner.dev.imp.simcargo.eu/portal',
        postLogoutRedirectUri: window.location.origin,
    },
};

export const tokenRequest_dev: typeof baseTokenRequest = {
    ...baseTokenRequest,
    scopes: [...baseTokenRequest.scopes, 'https://simcargodev.onmicrosoft.com/PortalApi/Access'],
};
