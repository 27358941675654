import { styled } from '@mui/material';
import { SimButton } from 'ui-library';

export const StyledButton = styled(SimButton)`
  &.tertiary {
    margin: 0 0.25rem 0 0;
    padding: 0.625rem;
    
    svg {
      width: 1.5rem;
      height: 1.5rem;

      path {
        fill: var(--white);
      }
    }
  }
`;
