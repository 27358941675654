import React from 'react';
import { Icon, SimApplication, SimSidebar } from 'ui-library';
import { AvailableApplications } from '../../../AvailableApplications';
import { styled } from '@mui/system';
import { ApplicationLink } from '../../../ApplicationLink';
import { useTranslation } from 'react-i18next';

const AppSwitcher = () => {
    const { t } = useTranslation();

    return (
        <>
      <SimSidebar title={t('application.switch')}
                  anchor="left"
                  triggerProps={{
                      tertiary: true,
                      icon: Icon.SWITCH,
                      sx: {
                          '&.tertiary': {
                              margin: '0 0.75rem 0 0.5rem',
                              padding: '0.625rem',
                              svg: {
                                  width: '1.5rem',
                                  height: '1.5rem',
                                  path: {
                                      fill: 'var(--white)',
                                  },
                              },
                          },
                      },
                  }}>
        <StyledApplicationLink application={SimApplication.PORTAL} />
        <StyledAvailableApplications />
      </SimSidebar>
    </>
    );
};

const StyledApplicationLink = styled(ApplicationLink)`
    width: auto;
    margin: 0 0 0.75rem 0;
`;

const StyledAvailableApplications = styled(AvailableApplications)`
    width: auto;
    margin: 0 0 0.75rem 0;
`;

export { AppSwitcher };
