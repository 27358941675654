import { useMemo, useState } from 'react';
import { FeatureFlag } from '../types/FeatureFlag';
import { FeatureFlagService } from '../services/FeatureFlagService';


/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} featureFlag App Config Feature Flag key
 * @returns the feature flag for the specified key
 */
const useFeatureFlag = (featureFlag: FeatureFlag) => {
    const [enabled, setEnabled] = useState(false);

    useMemo(async () => {
        const response = await FeatureFlagService.isFeatureFlagEnabled(featureFlag);
        setEnabled(response);
    }, [featureFlag]);

    return { enabled };
};

export { useFeatureFlag };
