import './ServiceInformation.scss';
import { useTranslation } from 'react-i18next';
import { Service } from '../../../types';
import { DateFormat, formatDateTime } from '../../../services';
import { Box } from '@mui/material';
import { Icon, SimIcon } from 'ui-library';

type Props = {
    service?: Service
};

export function ServiceInformation(props: Props) {
    const { service } = props;
    const { t, i18n } = useTranslation();

    if (!service) {
        return <></>;
    }

    return (
        <div className="service-information">
            <Box display="flex"
                 justifyContent="space-between"
                 className="service-state">
                <span className="service-name">
                    {service.serviceName}
                </span>
                <Box display="flex"
                     justifyContent="space-between"
                     alignItems="center">
                    <span className="state-label">
                        {service.state}
                    </span>
                    <SimIcon icon={Icon.GOOD}
                             color="var(--success)" />
                </Box>
            </Box>
            <div className="description">
                {t('pages.health.version')}: {service.version}
            </div>
            <div className="description">
                {t('pages.health.deployment')}: {formatDateTime(new Date(service.deployTime), i18n.language, DateFormat.SHORT)}
            </div>
            <div className="description">
                {t('pages.health.start')}: {formatDateTime(new Date(service.startTime), i18n.language, DateFormat.SHORT)}
            </div>
            <div className="description">
                {t('pages.health.restarts')}: {service.restarts}
            </div>
        </div>
    );
}