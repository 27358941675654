import { Menu } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { StyledMenuHeader } from './StyledMenuHeader';
import { StyledMenuItem } from './StyledMenuItem';
import { useTranslation } from 'react-i18next';
import { StyledButton } from './StyledButton';
import { Icon, useSimLogin } from 'ui-library';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

const UserMenu = () => {
    const { handleLogout } = useSimLogin();
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
    const userMenuButtonRef = useRef<HTMLButtonElement>(null);
    const { t } = useTranslation();
    const { enabled } = useFeatureFlag('portal-disable-usermanagement');
    const navigateToUserAccount = useCallback(() => {
        if (enabled) {
            window.open('/masterdata/settings/account', '_self');
        } else {
            window.open('/usermanagement/settings/account', '_self');
        }
    }, [enabled]);

    return (
        <>
            <StyledButton id="user-menu-button"
                          tertiary
                          icon={Icon.CONTACT}
                          ref={userMenuButtonRef}
                          onClick={() => setUserMenuOpen(true)} />
            <Menu anchorEl={userMenuButtonRef.current}
                  open={userMenuOpen}
                  onClose={() => setUserMenuOpen(false)}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  MenuListProps={{
                      sx: {
                          padding: 0,
                      },
                  }}
                  slotProps={{
                      paper: {
                          sx: {
                              boxShadow: 3,
                          },
                      },
                  }}>
                <StyledMenuHeader>{t('user-menu.header-user')}</StyledMenuHeader>
                <StyledMenuItem onClick={navigateToUserAccount}>{t('user-menu.settings')}</StyledMenuItem>
                <StyledMenuItem id="user-menu-logout-button"
                                onClick={handleLogout}>{t('user-menu.logout')}</StyledMenuItem>
            </Menu>
        </>
    );
};

export { UserMenu };
