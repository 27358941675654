import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppIcon } from '../AppIcon';
import { ApplicationService } from './ApplicationService';
import { Link as MuiLink, styled } from '@mui/material';
import { Icon, SimApplication, SimIcon } from 'ui-library';

type Props = {
    application: SimApplication
    withoutDescription?: boolean
    className?: string
};

const ApplicationLink = (props: Props) => {
    const { application, withoutDescription, className } = props;
    const applicationKey = Object.keys(SimApplication).filter(key => SimApplication[key] == application)[0] as keyof typeof SimApplication;
    const { t } = useTranslation();
    const [color] = useState<string>(ApplicationService.getColor(application));

    const getTargetUrl = useCallback(() => {
        return ApplicationService.getRelativeUrl(application);
    }, [application]);

    return (
        <Link href={`${getTargetUrl()}`}
              sx={{ background: color }}
              className={`application-link ${className || ''}`}>
            <AppIconWrapper className="application-link-icon">
                <AppIcon application={application}
                         size="1.875rem" />
            </AppIconWrapper>
            <ApplicationTitle className="application-link-title">
                {t(`application.${applicationKey}`)}
                {!withoutDescription && application !== SimApplication.PORTAL && (
                    <ApplicationCaption>{t(`application.description.${applicationKey as Exclude<keyof typeof SimApplication, "PORTAL">}`)}</ApplicationCaption>
                )}
            </ApplicationTitle>
            <OpenInNewTabLink className="application-link-open-in-new-tab"
                              onClick={(event) => {
                                  event.preventDefault();
                                  window.open(`${getTargetUrl()}`, '_blank')!.focus();
                              }}
                              title={t('common.openInNewTab')}>
                <SimIcon icon={Icon.OPEN_IN_NEW_TAB} />
            </OpenInNewTabLink>
        </Link>
    );
};

const Link = styled(MuiLink)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25rem;
    padding: 0.5rem;
    margin: 0.75rem;

    &,
    &:hover,
    &:active,
    &:focus {
        color: var(--black);
        text-decoration: none;
    }
`;

const AppIconWrapper = styled('span')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background: var(--white);
    border-radius: 0.25rem;
`;

const ApplicationTitle = styled('span')`
    display: block;
    font-weight: bold;
    align-items: center;
    justify-items: center;
    padding: 0 0.75rem;
    flex: 1;
`;

const ApplicationCaption = styled('span')`
    display: block;
    font-weight: normal;
    font-size: 0.8rem;
    margin-top: 0.25rem;
`;

const OpenInNewTabLink = styled('span')`
    padding: 0.5rem;

    path {
        fill: var(--blue)
    }
`;

export { ApplicationLink };
