import React from 'react';
import './i18n/config';
import {EnvironmentService} from './services';
import {createRoot} from 'react-dom/client';
import {AppRouter} from './AppRouter';
import {SimAuthConsumer, SimAuthProvider} from 'ui-library';

EnvironmentService.fetchCurrentEnvironment().then(() => {
    const container = document.getElementById('root') as HTMLDivElement;
    const root = createRoot(container);

    root.render(
        <SimAuthProvider msalConfig={EnvironmentService.getMsalConfig()}>
            <SimAuthConsumer b2cPolicies={EnvironmentService.getB2CPolicies()} tokenRequest={EnvironmentService.getTokenRequest()}>
                <AppRouter/>
            </SimAuthConsumer>
        </SimAuthProvider>
    );
});
