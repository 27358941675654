import { Box, styled } from '@mui/material';
import { Fragment, useCallback } from 'react';
import { AppSwitcher, StyledButton, UserMenu } from './components';
import { Icon, PClientLogo, SimBreadcrumbItem, SimIcon, ZClientLogo } from 'ui-library';
import { EnvironmentService } from '../../services';
import { LanguageMenu } from './components/LanguageMenu';

type Props = {
    breadcrumbItems?: SimBreadcrumbItem[]
}

const SimMenuBar = (props: Props) => {
    const { breadcrumbItems } = props;

    const navigateToHelp = useCallback(() => {
        window.open('https://www.meisternote.com/app/note/nddbUXOF-yGm/fits-plattform-doku-more', '_blank', 'noopener');
    }, []);

    return (
        <MenuBar>
            <Box display="flex"
                 alignItems="center">
                <AppSwitcher />
                <Box display="flex"
                     alignItems="center">
                    {breadcrumbItems && breadcrumbItems.length > 0 && (
                        <>
              <BreadcrumbHomeSeparator />
                            {breadcrumbItems.map((breadcrumbItem, index) => (
                                <Fragment key={`${breadcrumbItem.name}`}>
                                    <BreadcrumbItemLink href={breadcrumbItem.url}>
                                        <BreadcrumbItemIcon>
                                            {breadcrumbItem.icon}
                                        </BreadcrumbItemIcon>
                                        <Box display="flex"
                                             flexDirection="column">
                                            <BreadcrumbItemName>{breadcrumbItem.name}</BreadcrumbItemName>
                                            {breadcrumbItem.caption && breadcrumbItem.caption.trim().length > 0 && (
                                                <BreadcrumbItemCaption>{breadcrumbItem.caption}</BreadcrumbItemCaption>
                                            )}
                                        </Box>
                                    </BreadcrumbItemLink>
                                    {index + 1 < breadcrumbItems.length && (
                                        <BreadcrumbItemSeparator icon={Icon.RIGHT}
                                                                 color="#fff" />
                                    )}
                                </Fragment>
                            ))}
            </>
                    )}
                </Box>
            </Box>
            <Box display="flex"
                 alignItems="center">
                <StyledButton tertiary
                              icon={Icon.BELL}
                              disabled />
                <LanguageMenu />
                <UserMenu />
                <StyledButton tertiary
                              icon={Icon.HELP}
                              onClick={navigateToHelp} />
                <StyledLogo>
                    {EnvironmentService.isPartner ? <PClientLogo /> : <ZClientLogo />}
                </StyledLogo>
            </Box>
        </MenuBar>
    );
};

const MenuBar = styled('header')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.75rem;
    background: var(--very-dark-blue);
    color: var(--white);
`;

const StyledLogo = styled('div')`
    margin-left: 0.5rem;

    svg {
        height: 1.75rem;
        padding: 0 0.75rem 0 0;
    }
`;

const BreadcrumbHomeSeparator = styled('div')`
    background: var(--white);
    width: 1px;
    height: 1rem;
    margin: 0 1rem 0 0;
`;

const BreadcrumbItemLink = styled('a')`
    display: flex;
    align-items: center;

    &,
    &:hover,
    &:focus,
    &:active {
        color: var(--white);
        text-decoration: none;
    }
`;

const BreadcrumbItemIcon = styled('span')`
    display: flex;
    margin-right: 0.5rem;

    svg path {
        fill: var(--white);
    }
`;

const BreadcrumbItemTitle = styled('span')`
    display: block;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    color: var(--white);
`;

const BreadcrumbItemName = styled(BreadcrumbItemTitle)`
    font-size: 0.8125rem;
    color: var(--white);
`;

const BreadcrumbItemCaption = styled(BreadcrumbItemTitle)`
    font-size: 0.625rem;
    color: var(--white);
`;

const BreadcrumbItemSeparator = styled(SimIcon)`
    margin: 0 1rem;
`;

export { SimMenuBar };
