import './ApplicationHealth.scss';
import { ApplicationLink } from '../../components';
import { Service } from '../../types';
import { SimApplication, SimLoader } from 'ui-library';
import { ServiceInformation } from './ServiceInformation';

type ApplicationHealthProps = {
    application: SimApplication
    services: Service[]
}

const ApplicationHealth = (props: ApplicationHealthProps) => {
    const { application, services } = props;
    return (
        <div className="application-health">
            <ApplicationLink application={application}
                             withoutDescription />
            <div>
                {services.length === 0 && <SimLoader />}
                {services.length > 0 && services.map((service) => <ServiceInformation key={service.podName}
                                                                                      service={service} />)}
            </div>
        </div>
    );
};

export { ApplicationHealth };