import { SimApplication } from 'ui-library';

class ApplicationService {
    static getRelativeUrl(application: SimApplication): string {
        if (application == SimApplication.HEALTH_DASHBOARD) {
            return '/portal/health';
        }
        return `/${application}`;
    }

    static getColor(application: SimApplication): string {
        if (application === SimApplication.HEALTH_DASHBOARD) {
            return '#e5f3e3';
        } else {
            return '#e4e4e4';
        }
    }
}

export { ApplicationService };
