export enum DateFormat {
    SHORT = 'NUMERIC_SHORT',
    LONG = 'NUMERIC_LONG'
}

export function formatDate(date: Date | undefined, locale: string, format: DateFormat): string {
    if (!date) {
        return '';
    }
    switch (format) {
        case DateFormat.SHORT:
            return new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: '2-digit' }).format(date);
        case DateFormat.LONG:
            return new Intl.DateTimeFormat(locale, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
    }
}

export function formatDateTime(date: Date | undefined, locale: string, format: DateFormat): string {
    if (!date) {
        return '';
    }
    switch (format) {
        case DateFormat.SHORT:
            return new Intl.DateTimeFormat(locale, {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }).format(date).replace(',', '');
        case DateFormat.LONG:
            return new Intl.DateTimeFormat(locale, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            }).format(date).replace(',', '');
    }
}
