import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { DashboardPage, HealthPage } from './pages';
import React from 'react';
import App from './App';
import { SimErrorPage } from 'ui-library';
import { useTranslation } from 'react-i18next';

export const AppRouter = () => {
    const {t} = useTranslation();
    const routes = createRoutesFromElements(
        <Route element={<App />} errorElement={ <SimErrorPage text={t('error.unknownErrorText', { ns: ['general']})} />}>
            <Route path="/"
                   element={<DashboardPage />} />
            <Route path="/health"
                   element={<HealthPage />} />
            <Route path="/error"
                   element={<SimErrorPage />} />
            <Route path="*"
                   element={<Navigate replace
                                      to="/error" />} />
        </Route>,
    );

    const router = createBrowserRouter(routes, {
        basename: '/portal',
    });

    return <RouterProvider router={router} />;
};