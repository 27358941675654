import { baseMsalConfig, baseTokenRequest, msalConfigProd, msalConfigProdPartner } from 'ui-library';

export const msalConfig_prod: typeof baseMsalConfig = {
    ...msalConfigProd,
    auth: {
        ...msalConfigProd.auth,
        redirectUri: 'https://corporate.simcargo.eu/portal',
        postLogoutRedirectUri: window.location.origin,
    },
};

export const msalConfig_prod_partner: typeof baseMsalConfig = {
    ...msalConfigProdPartner,
    auth: {
        ...msalConfigProdPartner.auth,
        redirectUri: 'https://partner.simcargo.eu/portal',
        postLogoutRedirectUri: window.location.origin,
    },
};

export const tokenRequest_prod: typeof baseTokenRequest = {
    ...baseTokenRequest,
    scopes: [...baseTokenRequest.scopes, 'https://simcargoprod.onmicrosoft.com/PortalApi/Access'],
};
