import './App.scss';
import { useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { SimMenuBar } from './components';
import { Icon, SimApp, SimIcon, useSimLogin } from 'ui-library';
import { useTranslation } from 'react-i18next';

export const App = () => {
    const { isAuthenticated } = useSimLogin();
    const { t } = useTranslation();
    const location = useLocation();

    const getMenuBarItems = useCallback(() => {
        if (location.pathname.startsWith('/health')) {
            return [{
                name: t('pages.health.breadcrumb.name'),
                icon: <SimIcon icon={Icon.HEALTH}
                               size="1.5rem"
                               color="#fff" />,
                url: window.location.href,
            }];
        } else {
            return [{
                name: t('pages.dashboard.breadcrumb.name'),
                icon: <SimIcon icon={Icon.DASHBOARD}
                               size="1.5rem"
                               color="#fff" />,
                url: window.location.href,

            }];
        }
    }, [t, location]);

    return isAuthenticated && (
        <SimApp>
            <div className="app">
                <SimMenuBar breadcrumbItems={getMenuBarItems()} />
                <Outlet />
            </div>
        </SimApp>
    );
};

export default App;
