import { useEffect, useState } from 'react';
import './HealthPage.scss';
import { Service } from '../../types';
import _ from 'lodash';
import axios from 'axios';
import { HealthPageService } from './HealthPageService';
import { ApplicationHealth } from './ApplicationHealth';
import { Grid } from '@mui/material';
import { SimApplication, SimScrollable } from 'ui-library';

const APPLICATIONS = [
    SimApplication.USER_MANAGEMENT,
    SimApplication.LCC,
    SimApplication.MASTER_DATA,
    SimApplication.DOPS,
    SimApplication.SEARCH,
    SimApplication.REPORTING,
    SimApplication.NETWORK_MANAGEMENT,
];

const HealthPage = () => {
    const [services, setServices] = useState<Map<SimApplication, Service[]>>(new Map<SimApplication, Service[]>());

    const loadServiceInformation = () => {
        axios.get('/portal/api/health/kubernetes/services')
             .then((response: { data: Service[] }) => {
                 const { data } = response;
                 const healthMap = new Map<SimApplication, Service[]>();
                 const applications = _.groupBy(data, 'application');
                 for (const applicationName of Object.keys(applications)) {
                     let application = HealthPageService.getApplication(applicationName);
                     if (application) {
                         // concat services to combine dops and shipment-management
                         healthMap.set(application, _.concat(healthMap.get(application) ?? [], applications[applicationName]));
                     } else {
                         console.warn(`No mapping for application "${applicationName}". Entry skipped.`);
                     }
                 }
                 setServices(healthMap);
             })
             .catch(e => {
                 console.error(e);
             });
    };

    useEffect(() => {
        loadServiceInformation();
        const interval = setInterval(loadServiceInformation, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="health-page">
            <div className="content">
                <SimScrollable>
                    <Grid container
                          spacing={2}>
                        {APPLICATIONS.map(application => (
                            <Grid key={application.toString()}
                                  item
                                  xs={12}
                                  sm={6}
                                  lg={4}>
                                <ApplicationHealth application={application}
                                                   services={services.get(application) || []} />
                            </Grid>
                        ))}
                    </Grid>
                </SimScrollable>
            </div>
        </div>
    );
};

export { HealthPage };
