import { baseMsalConfig, baseTokenRequest, msalConfigQa, msalConfigQaPartner } from 'ui-library';

export const msalConfig_qa: typeof baseMsalConfig = {
    ...msalConfigQa,
    auth: {
        ...msalConfigQa.auth,
        redirectUri: 'https://corporate.qa.imp.simcargo.eu/portal',
        postLogoutRedirectUri: window.location.origin,
    },
};
export const msalConfig_qa_partner: typeof baseMsalConfig = {
    ...msalConfigQaPartner,
    auth: {
        ...msalConfigQaPartner.auth,
        redirectUri: 'https://partner.qa.imp.simcargo.eu/portal',
        postLogoutRedirectUri: window.location.origin,
    },
};

export const tokenRequest_qa: typeof baseTokenRequest = {
    ...baseTokenRequest,
    scopes: [...baseTokenRequest.scopes, 'https://simcargoqa.onmicrosoft.com/PortalApi/Access'],
};
