import { SimApplication } from 'ui-library';

class HealthPageService {
    static getApplication(applicationName: string): SimApplication | undefined {
        switch (applicationName) {
            case 'search':
                return SimApplication.SEARCH;
            case 'lcc':
                return SimApplication.LCC;
            case 'usermanagement':
                return SimApplication.USER_MANAGEMENT;
            case 'masterdata':
                return SimApplication.MASTER_DATA;
            case 'dops':
            case 'shipment-management':
                return SimApplication.DOPS;
            case 'portal':
                return SimApplication.PORTAL;
            case 'reporting':
                return SimApplication.REPORTING;
            case 'networkmanagement':
                return SimApplication.NETWORK_MANAGEMENT;
            default:
                return undefined;
        }
    };
}

export { HealthPageService };