import axios from 'axios';

export const FeatureFlagService = {
    async isFeatureFlagEnabled(featureFlag: string): Promise<boolean> {
        try {
            let response = await axios
                .get('/portal/api/feature-flag', {params: {featureFlagName: featureFlag}});
            return await response.data;
        } catch (e) {
            return false;
        }
    },
};
