import i18n from 'i18next';
import de from './de/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import fr from './fr/translation.json';
import { resources as libResources } from 'ui-library';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
    de: {
        translation: de,
        general: libResources.de.general,
    },
    en: {
        translation: en,
        general: libResources.en.general,
    },
    es: {
        translation: es,
        general: libResources.es.general,
    },
    fr: {
        translation: fr,
        general: libResources.fr.general,
    },
} as const;

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng: ['en', 'de'],
});
