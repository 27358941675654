import './DashboardPage.scss';
import { SimScrollable, SimTitle } from 'ui-library';
import { AvailableApplications } from '../../components';
import { useTranslation } from 'react-i18next';

export function DashboardPage() {
    const { t } = useTranslation();
    return (
        <div className="dashboard-page">
            <div className="content">
                <SimScrollable>
                    <SimTitle>{t('pages.dashboard.title')}</SimTitle>
                    <AvailableApplications displayAs="grid" />
                </SimScrollable>
            </div>
        </div>
    );
}
