import { StyledButton } from './StyledButton';
import { Icon } from 'ui-library';
import { Menu } from '@mui/material';
import { StyledMenuHeader } from './StyledMenuHeader';
import { StyledMenuItem } from './StyledMenuItem';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { EnvironmentService } from '../../../services';

type LanguageConfig = { language: 'german' | 'english' | 'spain' | 'french'; code: string }

const defaultLanguages: LanguageConfig[] =
    [{ language: 'german', code: 'de-DE' },
        { language: 'english', code: 'en-GB' },
        { language: 'spain', code: 'es-ES' }];
const qaLanguages: LanguageConfig[] =
    [{ language: 'german', code: 'de-DE' },
        { language: 'english', code: 'en-GB' },
        { language: 'spain', code: 'es-ES' }];
const prodLanguages: LanguageConfig[] =
    [{ language: 'german', code: 'de-DE' },
        { language: 'english', code: 'en-GB' },
        { language: 'spain', code: 'es-ES' }];

export function LanguageMenu() {
    const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
    const languageMenuButtonRef = useRef<HTMLButtonElement>(null);
    const { t } = useTranslation();
    const config = useCallback((): LanguageConfig[] => {
        switch (EnvironmentService.env) {
            case 'qa':
                return qaLanguages;
            case 'prod':
                return prodLanguages;
            default:
                return defaultLanguages;
        }
    }, [EnvironmentService.env]);
    const changeLanguage = (languageCode: string) => {
        i18n.changeLanguage(languageCode);
        location.reload();  // reload seems necessary
    };
    return (
        <>
      <StyledButton tertiary
                    icon={Icon.LANGUAGE}
                    ref={languageMenuButtonRef}
                    onClick={() => setLanguageMenuOpen(true)} />
      <Menu anchorEl={languageMenuButtonRef.current}
            open={languageMenuOpen}
            onClose={() => setLanguageMenuOpen(false)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            MenuListProps={{
                sx: {
                    padding: 0,
                },
            }}
            slotProps={{
                paper: {
                    sx: {
                        boxShadow: 3,
                    },
                },
            }}>
        <StyledMenuHeader>{t('language-menu.header')}</StyledMenuHeader>
          {config().map((c) =>
              <StyledMenuItem key={`menu-item-${c.code}`}
                              onClick={() => changeLanguage(c.code)}>
                  {t(`language-menu.${c.language}`)}
              </StyledMenuItem>)}
      </Menu>
    </>
    );
}